<template>
  <div>
    <list-template
      :search-config="searchConfig"
      @onSearch="onSearch"
      @onReset="onReset"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange"
      @onAdd="$router.push('./add')"
    >
    </list-template>
    <Dialog :visible.sync="visible" showLine title="历史记录" :showFooter="false">
      <TimeLine :list="list" reverse v-loading="dialogLoading"></TimeLine>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import TimeLine from '@/components/common/TimeLine'
import { tableListMixin, getAreaDataMixin } from '@/utils/mixins'
import { zs_opt_history } from '@/api'
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin, getAreaDataMixin],
  components: { Dialog, TimeLine },
  created() {
    this.getAreaData('1,2', this.getConfig)
    this.getData()
  },
  methods: {
    onBeforeSearch(val) {
      const { city } = val
      if (city && city.length) {
        this.search.city_code = val.city[1]
      }
    },
    getConfig(list) {
      this.searchConfig[0].options = list
      // this.searchConfig[0].default = [2702, 2703]
    },
    getData() {
      this.getDataApi('/zhaosheng/api/recruit-2024/exam/list-exam-conf')
    },
    handleClick(row, handle) {
      const { type } = handle
      if(type === 'edit') {
        this.$router.push({ path: `./edit/?id=${row.id}` })
      } else if(type === 'viewHistory') {
        this.getHistory(row.id)
        this.visible = true
      }
    },
    getHistory(id) {
      this.list = []
      this.dialogLoading = true
      zs_opt_history({ type: 'exam_2024', id }).then(res => {
        const { list } = res
        this.list = list.map(i => ({ content: i.change_detail }))
      }).finally(() => this.dialogLoading = false)
    },
  },
  data() {
    return {
      visible: false,
      dialogLoading: false,
      tableConfig: [
        { prop: 'city_name', label: '参考城市' },
        // { prop: 'area_name', label: '区/县' },
        { prop: 'score_type', label: '总分计分方式', render: (row) => row.score_type ? '等级' : '分数' },
        { prop: 'total_score_num', label: '中考满分', render: (row) => row.score_type ? row.total_score_level : row.total_score_num },
        { label: '操作', handle: true }
      ],
      searchConfig: [
        { tag: 'cascader', placeholder: '筛选城市', prop: 'city', options: [] }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
